import { CustomLink } from "data/types";
import React, { FC,useState } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";
import {
  Card,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

export interface PaginationProps {
  className?: string;
  filterCategoryData: any;
  getListData: any;
  listCount: any;
  searchQuery?: any;
}

const ITEMS_PER_PAGE = 18;

const Pagination: FC<PaginationProps> = ({ className = "",filterCategoryData,getListData,listCount,searchQuery="" }) => {

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * filterCategoryData.limit;
  const endIndex = startIndex + filterCategoryData.limit;

  const totalPages = Math.ceil(listCount / filterCategoryData.limit);

  const DEMO_PAGINATION: CustomLink[] = [];
  for (let i = 1; i <= totalPages; i++) {
    DEMO_PAGINATION.push({
      label: i.toString(),
      href: `#page-${i}`, // Assuming you want different href for each page
    });
  }

  let queryString="";
  if (searchQuery) {
    queryString = `?search=${encodeURIComponent(searchQuery)}`;
  }

  const handlePageChange = async (page:any) => {
      setCurrentPage(page);
      filterCategoryData.start = (page - 1) * filterCategoryData.limit;
      filterCategoryData.page = page;
      getListData();

      window.scrollTo({
        top: 0,
        behavior: 'smooth', // use 'auto' for instant scroll
      });
  };

  const renderItem = (pag: CustomLink, index: number) => {
    if (index === filterCategoryData.page-1) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-primary-6000 hover:text-white hover:border-0 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        to={queryString}
        onClick={() => handlePageChange(index+1)}
      >
        {pag.label}
      </Link>
    );
  };

  return (
    <>
    <div className="text-left">
      <Typography placeholder="" variant="small" color="blue-gray" className="font-normal text-left"
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      >
      Page {filterCategoryData.page} of {totalPages}
      </Typography>
    </div>
    <div className="text-left">
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {DEMO_PAGINATION.map(renderItem)}
    </nav>
    </div>
    </>
  );
};

export default Pagination;
